.flex-container {
    
    display: inline-flex;
    width:100%;
    text-align:center;
}

.flex-child {
    flex:2;
    width:50%;
    text-align:left;
}

.flex-child:first-child {
    flex:1;
    margin-right: 20px;
    width:50%;
    text-align: right;
} 


.instructions{
    padding:60px;
    height:100%;
    min-height: 400px;
    font-size:large;
    
}