.getstarted{
    text-align:center;
    font-size:xx-large;
}

.doclink:link,.doclink:visited, .doclink:hover, .doclink:active {
    text-transform: uppercase;
}

.doclink:link,.doclink:hover{
color:#ff9606;
}


.doclink:visited,doclink:active{
color:#fcaf3b;
}