.ResponseContainer{
  -webkit-text-size-adjust: 100%;
  font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  tab-size: 4;
  --color-primary: #19c37d;
  --color-secondary: #715fde;
  --color-error: #ef4146;
  --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
  --text-primary: #202123;
  --text-default: #353740;
  --text-secondary: #6e6e80;
  --text-disabled: #acacbe;
  --text-error: var(--color-error);
  color-scheme: light;
  border: 0 solid #d9d9e3;
  box-sizing: border-box;
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59,130,246,.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: .875rem;
  line-height: 1.25rem;
  overflow:auto;
  /*max-height:65%;
  width:84%;
  max-width:84%;
  height:65%;
  position:absolute;*/
  width:100%;
  margin-bottom:275px;
  
}


.ResponseContainer:nth-child(even){
    font-weight: bold
}

.QuestionContainer{
  -webkit-text-size-adjust: 100%;
  font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  tab-size: 4;
  --color-primary: #19c37d;
  --color-secondary: #715fde;
  --color-error: #ef4146;
  --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
  --text-primary: #202123;
  --text-default: #353740;
  --text-secondary: #6e6e80;
  --text-disabled: #acacbe;
  --text-error: var(--color-error);
  color-scheme: light;
  line-height: inherit;
  border: 0 solid #d9d9e3;
  box-sizing: border-box;
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59,130,246,.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-bg-opacity: 1;
  border-top-width: 0;
  background-image: linear-gradient(180deg,hsla(0,0%,100%,0) 13.94%,#fff 54.73%);
  position: fixed;
  bottom: 0;
  margin:0;
  padding:0;
  background-color: white;
  
}

.flex-container {
    
    display: inline-flex;
    width:100%;
    
}

.flex-child:first-child {
    flex:1;
    margin-right: 20px;
    width:20%;
    
} 
.flex-child-second {
    flex:2;
    width:80%;
    
}

.question_noout_panel{
    padding:20px;
    margin:20px;
    margin-left:5px;

}
.question_noout_panel>label{
    font-size:xx-large;
    font-weight:bold;
    margin-top:25px;
    margin-bottom:25px;
}
.question_noout_container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
.question_noout_child{
    border: 1px solid silver;
    margin-top:50px;
    text-align:center;
    padding: 20px;
}

.panelicon{
    font-size:72px;
}

ul.infodisplay{
    list-style-type: none;
    margin:0;
    padding:0;
}
ul.infodisplay>li{
    margin-top:25px;
}
ul.infodisplay>li.heading{
    font-weight:bold;
    font-size:x-large
}
div.answerblock{
    padding-left:40px;
}

div.answerblock:nth-child(even){
    font-weight: bold !important;
    background-color:#dedde4;
}
div.answerblock:nth-child(odd){
    font-weight: bold !important;
    background-color:#f5f5f7;
}

ul.answerlist_context{
    background-color:palegoldenrod;
}

ul.answerlist_choices{
    background-color:palegreen;
}
ul.answerlist:nth-child(even){
    background-color: palegreen;
}

li.answerlistitem:nth-child(even){
    background-color:#19c37d;
}

.pageSpan{
    box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;
}
.pageSpan2{
    box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;
}
.svgout{
    display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;
}

.svgout2{
    position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;
}
.promptcontainer{
    -webkit-text-size-adjust: 100%;
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    font-size: .875rem;
    line-height: 1.25rem;
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    width: 100%;
    border-bottom-width: 1px;
    border-color: rgba(0,0,0,.1);
    --tw-text-opacity: 1;
    color: rgba(52,53,65,var(--tw-text-opacity));
}

.messagecontainer{
    -webkit-text-size-adjust: 100%;
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    --tw-text-opacity: 1;
    color: rgba(52,53,65,var(--tw-text-opacity));
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    margin: auto;
    display: flex;
    font-size: 1rem;
    line-height: 1.5rem;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    padding-left: 0;
    padding-right: 0;
    max-width: 48rem;
}

.leftmargincontainer{
    -webkit-text-size-adjust: 100%;
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    --tw-text-opacity: 1;
    color: rgba(52,53,65,var(--tw-text-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    position: relative;
    display: flex;
    width: 30px;
    flex-direction: column;
    align-items: flex-end;
}

.relative{
    position:relative;
}
.flex{
    display:flex;
}

.outputcontainer{
    -webkit-text-size-adjust: 100%;
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    --tw-text-opacity: 1;
    color: rgba(52,53,65,var(--tw-text-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: .75rem;
    width: calc(100% - 115px);
}

.flex-grow{
    flex-grow: 1;
}
.flex-col{
    flex-direction: column;
}
.gap-3{
    gap: .75rem;
}
.justify-between{
    justify-content: space-between;
}

.prompttext{
    -webkit-text-size-adjust: 100%;
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    --tw-text-opacity: 1;
    color: rgba(52,53,65,var(--tw-text-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    display: flex;
    min-height: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    white-space: pre-wrap;
}

.promptactioncontainer{
    -webkit-text-size-adjust: 100%;
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    font-size: 1rem;
    line-height: 1.5rem;
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    visibility: visible;
    display: flex;
    justify-content: center;
    --tw-text-opacity: 1;
    color: rgba(172,172,190,var(--tw-text-opacity));
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-translate-x: 100%;
    gap: .25rem;
    align-self: center;
    padding-left: .5rem;
}

.promptactionbutton{
        -webkit-text-size-adjust: 100%;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    --tw-text-opacity: 1;
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    color: inherit;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
    text-transform: none;
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
    cursor: pointer;
    border-radius: .375rem;
    padding: .25rem;
    visibility: visible;
}

.responsebgcolor{
    background-color: rgb(16, 163, 127);
}

.responseoutputcontainer{
    -webkit-text-size-adjust: 100%;
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    font-size: .875rem;
    line-height: 1.25rem;
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    width: 100%;
    border-bottom-width: 1px;
    border-color: rgba(0,0,0,.1);
    --tw-bg-opacity: 1;
    background-color: rgba(247,247,248,var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(52,53,65,var(--tw-text-opacity));
}

.leftmarginiconcontainer{
    -webkit-text-size-adjust: 100%;
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    --tw-bg-opacity: 1;
    font-size: 1rem;
    line-height: 1.5rem;
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    position: relative;
    display: flex;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    border-radius: .125rem;
    padding: .25rem;
    --tw-text-opacity: 1;
    color: rgba(255,255,255,var(--tw-text-opacity));
    background-color: rgb(16, 163, 127);
}

.w-6{
    width: 1.5rem;
}
.h-6{
    height: 1.5rem;
}

.outputresponsecontainer{
    -webkit-text-size-adjust: 100%;
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    color: rgba(52,53,65,var(--tw-text-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    display: flex;
    min-height: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    white-space: pre-wrap;
}

.outputmarkdowncontainer{
    -webkit-text-size-adjust: 100%;
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    tab-size: 4;
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
    --text-primary: #202123;
    --text-default: #353740;
    --text-secondary: #6e6e80;
    --text-disabled: #acacbe;
    --text-error: var(--color-error);
    color-scheme: light;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    white-space: pre-wrap;
    border: 0 solid #d9d9e3;
    box-sizing: border-box;
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    color: var(--tw-prose-body);
    --tw-prose-body: #374151;
    --tw-prose-headings: #111827;
    --tw-prose-lead: #4b5563;
    --tw-prose-links: #111827;
    --tw-prose-bold: #111827;
    --tw-prose-counters: #6b7280;
    --tw-prose-bullets: #d1d5db;
    --tw-prose-hr: #e5e7eb;
    --tw-prose-quotes: #111827;
    --tw-prose-quote-borders: #e5e7eb;
    --tw-prose-captions: #6b7280;
    --tw-prose-code: #111827;
    --tw-prose-pre-code: #e5e7eb;
    --tw-prose-pre-bg: #1f2937;
    --tw-prose-th-borders: #d1d5db;
    --tw-prose-td-borders: #e5e7eb;
    --tw-prose-invert-body: #d1d5db;
    --tw-prose-invert-headings: #fff;
    --tw-prose-invert-lead: #9ca3af;
    --tw-prose-invert-links: #fff;
    --tw-prose-invert-bold: #fff;
    --tw-prose-invert-counters: #9ca3af;
    --tw-prose-invert-bullets: #4b5563;
    --tw-prose-invert-hr: #374151;
    --tw-prose-invert-quotes: #f3f4f6;
    --tw-prose-invert-quote-borders: #374151;
    --tw-prose-invert-captions: #9ca3af;
    --tw-prose-invert-code: #fff;
    --tw-prose-invert-pre-code: #d1d5db;
    --tw-prose-invert-pre-bg: rgba(0,0,0,.5);
    --tw-prose-invert-th-borders: #4b5563;
    --tw-prose-invert-td-borders: #374151;
    font-size: 1rem;
    line-height: 1.75;
    width: 100%;
    word-wrap: break-word;
    max-width: none;
}

.context {
    background-color: #FFF5FA;
    color: #000000;
    border: 1px dashed #6D6D6D;
    padding: 11px;
    border-radius: 10px;
}

.docset {
    background-color:  #FCFFEB;
    color: #000000;
    border: 1px dashed #6D6D6D;
    padding: 11px;
    border-radius: 10px;
}

.question {
    background-color: #FFEBDE;
    color: #000000;
    border: 1px dashed #6D6D6D;
    padding: 11px;
    border-radius: 10px;
}

.response{
    background-color: #E6FFEC;
    color: #000000;
    border: 1px dashed #6D6D6D;
    padding: 11px;
    border-radius: 10px;
}

.support_item_heading_container{
    display: grid;
    grid-template-columns: 1.75fr .25fr;
    grid-gap: 10px;
}
.support_item_heading_child{
    margin-top:10px;
    text-align:left;
    padding: 5px;
    display:inline;
}

ul.support_item_list{
    list-style-type:none
}
li.support_item{
    vertical-align: top;
    padding:10px;
    border-bottom:1px solid silver;
}

