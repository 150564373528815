html, body, #app, #app>div{
  height: 100%;
  width: 100%;
  
}

body > #root > div {
  height: 100%;
  width: 100%;
  overflow:hidden;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  
}

.PageContainerRoot{
  -webkit-text-size-adjust: 100%;
  font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  tab-size: 4;
  --color-primary: #19c37d;
  --color-secondary: #715fde;
  --color-error: #ef4146;
  --gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
  --text-primary: #202123;
  --text-default: #353740;
  --text-secondary: #6e6e80;
  --text-disabled: #acacbe;
  --text-error: var(--color-error);
  color-scheme: light;
  line-height: inherit;
  border: 0 solid #d9d9e3;
  box-sizing: border-box;
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59,130,246,.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  flex: 1 1 0%;
  max-height:70%;
  width: 100%;
  overflow:hidden;
  margin:0xp !important;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#main-content {
  padding:0px;
}

#react-admin-title{
  min-width: 300px;
}

:root, [data-amplify-theme] {
  --amplify-colors-font-primary: #ff6600;
  /* you can also use references: */
  --amplify-colors-font-secondary: var(--amplify-colors-neutral-60);
}


.amplify-button[data-variation='primary']{
  background: #fcaf3b;
}

.amplify-button:hover[data-variation='primary']{
  background: #ff960b;
}

.amplify-button[data-variation='link']{
  color: #fcaf3b;
}

.amplify-button:hover[data-variation='link']{
  color: #ff960b;
  background: #e6e6e6;
}

.appBarLogo{
  width:100%;
  text-align:center;
}
@media screen and (max-width: 320px) {

  .appBarLogo{
    display: none;
  }

}
